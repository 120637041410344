<template>
  <div>
    <profile-info v-if="permisisons.vendor && permisisons.vendor.profile" />
    <Dashboard
      v-if="permisisons.vendor && permisisons.vendor.statistics"
      :data="statistics"
      :busy="dashboardBusy"
    />
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ProfileInfo from "@/components/Profile/ProfileInfo/index.vue";
import Dashboard from "@/views/dashboard/index.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    ProfileInfo,
    Dashboard,
  },
  data() {
    return {
      statistics: [],
      dashboardBusy: false,
    };
  },
  computed: {
    permisisons() {
      return this.$store.getters?.getUserPermission;
    },
  },
  mounted() {
    this.getStatisticsData();
  },
  methods: {
    async getStatisticsData() {
      this.dashboardBusy = true;
      try {
        const response = await this.$http.get("vendor/statistics");
        if (response.status === 200 || response.status === 201) {
          this.statistics = response.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.dashboardBusy = false;
      }
    },
  },
};
</script>

<style></style>
