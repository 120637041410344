<template>
  <div>
    <section id="dashboard-ecommerce">
      <b-row class="match-height">
        <b-col cols="12" >
          <ecommerce-statistics :data="statisticsItems" />
            <overlay-component :is-loading="busy"/>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import Ecommerce from './ecommerce/Ecommerce.vue'
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import {BCol, BRow} from "bootstrap-vue";
import EcommerceStatistics from "@/views/dashboard/ecommerce/EcommerceStatistics.vue";


export default {
    name: 'Dashboard',
  props:["busy","data"],
    components:{
      EcommerceStatistics, BCol, BRow,
      OverlayComponent,
        Ecommerce,
    }
    ,data(){
      return {
        statisticsItems: [
          {
            icon: "ShoppingCartIcon",
            color: "light-primary",
            label:"orders_count",
            title: "",
            subtitle: this.$t("statistics.orders"),
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "UsersIcon",
            color: "light-info",
            label:"clients_count",

            title: "",
            subtitle: this.$t("statistics.clients_count"),
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "BoxIcon",
            color: "light-danger",
            title: "",
            label:"products_count",
            subtitle: this.$t("statistics.products"),
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "DollarSignIcon",
            label:"sales",
            color: "light-success",
            title: "",
            subtitle: this.$t('statistics.sales'),
            customClass: "",
          },
        ],
      }
  },

  methods:{
    handelPresentingData(data){
      const dataKeys = Object.keys(data);
      dataKeys.forEach((ele)=>{
        this.statisticsItems.filter((item)=>(item.label === ele))[0].title = data[ele]
      })

    }

  },
  watch:{
      data(newVal){
        this.handelPresentingData(newVal)
      }
  }
}
</script>

<style lang="scss">
@import "./_index";
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
