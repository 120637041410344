<template>
  <b-modal id="edit-profile-modal" centered hide-footer>
    <validation-observer ref="editProfileForm">
      <b-form>
        <div class="vendor-request-modal-content">
          <div
            class="vendor-request-modal-cover position-relative"
            :style="{ backgroundImage: 'url(' + header + ')' }"
          >
            <div class="overlay">
              <label
                for="header_image"
                class="position-absolute p-0 cursor-pointer"
              >
                <img :src="cameraIcon" alt="camera icon" />
              </label>
              <RecommendationUploadImage
                :overlay="true"
                :dimensions="{ width: 1200, height: 750 }"
              />
            </div>
            <b-form-file
              id="header_image"
              v-model="header_image"
              accept="image/*"
              class="d-none"
            />
          </div>
          <b-avatar
            class="vendor-request-modal-avatar"
            size="8rem"
            :alt="data.store_name"
            :src="logo"
            badge
            badge-variant="light"
          >
            <template #badge>
              <label for="logo" class="cursor-pointer">
                <FeatherIcon icon="CameraIcon" class="text-primary" size="17" />
              </label>
              <b-form-file
                id="logo"
                v-model="logo_image"
                accept="image/*"
                class="d-none"
              />
            </template>
          </b-avatar>
          <RecommendationUploadImage
          :dimensions="{ width: 110, height: 110 }"
          class="custom-recommendation-image"
        />
          <b-form-group
            label-cols="12"
            label-for="store_name"
            :label="$t('vendors/vendorsList/vendors-requests/vendor_name')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('g.store_name')"
              rules="required"
            >
              <b-form-input id="store_name" v-model="data.store_name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-cols="12"
            label-for="about"
            :label="$t('vendors/vendorsList/vendors-requests/description')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('g.store_about')"
            >
              <b-form-textarea
                id="about"
                v-model="data.about"
                rows="5"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- priority -->
          <!-- <b-form-group
          label-cols="12"
          label-for="priority"
          :label="$t('vendors/vendorsList/vendors-requests/priority')"
        >
          <b-form-select id="priority" v-model="data.priority">
            <option :value="5">
              {{ $t("vendors/vendorsList/accepted-vendors/very_high") }}
            </option>
            <option :value="4">
              {{ $t("vendors/vendorsList/accepted-vendors/high") }}
            </option>
            <option :value="3">
              {{ $t("vendors/vendorsList/accepted-vendors/medium") }}
            </option>
            <option :value="2">
              {{ $t("vendors/vendorsList/accepted-vendors/low") }}
            </option>
            <option :value="1">
              {{ $t("vendors/vendorsList/accepted-vendors/very_low") }}
            </option>
          </b-form-select>
        </b-form-group> -->
          <div class="row d-flex justify-content-center">
            <b-button
              variant="primary"
              class="col col-8 m-1"
              @click="submitForm"
              >{{ $t("g.edit") }}
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <overlay-component :isLoading="isLoading" />
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
import { required, email } from "@validations";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

import {
  BModal,
  BAvatar,
  BButton,
  BFormFile,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BForm,
} from "bootstrap-vue";
export default {
  name: "EditProfileModal",
  props: ["data", "archive"],
  components: {
    BModal,
    BAvatar,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    OverlayComponent,
    ValidationProvider,
    ValidationObserver,
    BForm,
    RecommendationUploadImage,
  },
  data() {
    return {
      header_image: null,
      cameraIcon: require("@/assets/images/icons/camera.svg"),
      logo_image: null,
      vendoreHeaderImage: require("@/assets/images/icons/dummyProfileHeader.png"),
      isLoading: false,
    };
  },
  computed: {
    header() {
      if (this.header_image) {
        this.data.header = this.header_image;

        return URL.createObjectURL(this.header_image);
      } else if (this.data.header) {
        return this.data.header;
      } else {
        return this.vendoreHeaderImage;
      }
    },
    logo() {
      if (this.logo_image) {
        this.data.logo = this.logo_image;

        return URL.createObjectURL(this.logo_image);
      } else if (this.data.logo) {
        return this.data.logo;
      } else if (this.data.store_name) {
        return this.$helpers.uiAvatar(this.data.store_name);
      }
    },
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      try {
        const validation = await this.$refs.editProfileForm.validate();
        if (validation) {
          const formData = new FormData();
          const editedItems = [];
          for (const item in this.data) {
            if (this.data[item] != this.archive[item]) {
              editedItems.push(item);

              formData.append(item, this.data[item]);
            }
          }
          const response =
            editedItems.length > 0
              ? await this.$http.post(
                  `vendor/vendors/${this.data.slug}?_method=put`,
                  formData
                )
              : this.$bvModal.hide("edit-profile-modal");
          if (
            response &&
            (response?.status == 200 || response?.status == 201)
          ) {
            this.$emit("reset");
            this.$bvModal.hide("edit-profile-modal");
            this.$helpers.makeToast(
              "success",
              response.data.message,
              response.data.message
            );
          }
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
