<template>
  <div class="profile-info">
    <div class="card">
      <img
        :src="
          storeData.header || require(`@/assets/images/pages/vendor-cover.jpeg`)
        "
        alt="profile-cover"
        class="profile-cover"
      />
      <div class="user-info">
        <b-avatar size="7.5rem" :src="storeData.logo" />
        <h3>{{ storeData.store_name }}</h3>
      </div>
      <div class="description">
        <h5>
          {{ $t("g.profile/description/head") }}
        </h5>
        <div ref="description">
          {{ storeData.about }}
        </div>
      </div>
      <div class="actions">
        <b-button
          variant="primary"
          class="edit-button col-12 col-lg-3 col-md-6"
          @click="editHandelar"
          >{{ $t("g.edit") }} </b-button
        ><b-button
          variant="link"
          class="col-12 col-lg-3 col-md-6"
          @click="openExternalLink(storeData.slug)"
          >{{ $t("viewInWebSite") }}
        </b-button>
      </div>
      <overlay-component :is-loading="isLoading" />
    </div>
    <edit-profile-modal
      :data="storeData"
      :archive="archive"
      @reset="getMyProfileData"
    />
  </div>
</template>
<script>
import EditProfileModal from "./EditProfileModal/index.vue";
import { BAvatar, BButton } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent/index.vue";
export default {
  components: {
    OverlayComponent,
    BAvatar,
    BButton,
    EditProfileModal,
  },
  data() {
    return {
      storeData: {
        logo: null,
        header: null,
        about: null,
        slug: null,
        store_name: null,
      },
      archive: {},
      isLoading: true,
    };
  },
  created() {
    this.getMyProfileData();
  },
  computed: {
    webSiteBaseUrl() {
      return process.env.VUE_APP_WEB_SITE_BASE_URL;
    },
    permissions() {
      return this.$store?.getters?.getUserPermission;
    },
  },
  methods: {
    generateLink(slug) {
      return this.webSiteBaseUrl + "vendor/" + slug;
    },
    openExternalLink(slug) {
      window.open(this.generateLink(slug), "_blank");
    },
    async getMyProfileData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("vendor/my-vendor");
        if (response.status === 200 || response.status === 201) {
          const vendor = response.data.data;
          for (let item in this.storeData) {
            if (item === "logo" || item === "header") {
              this.storeData[item] = vendor[item]?.path;
              this.archive[item] = vendor[item]?.path;
            } else {
              this.storeData[item] = vendor[item];
              this.archive[item] = vendor[item];
            }
          }
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    handelProfileImages() {
      this.userData.media.map((ele) => {
        if (ele.name == "avatar") {
          this.avater = ele.path;
        } else if (ele.name == "cover") {
          this.coverProfile = ele.path;
        }
      });
    },
    editHandelar() {
      this.$bvModal.show("edit-profile-modal");
    },
  },
};
</script>
<style lang="scss">
@import "./index";
</style>
