<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">

      <b-col cols="12" >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {


      }

  }
  }
  // created() {
  //   // data
  //   this.$http.get("/ecommerce/data").then((response) => {
  //     this.data = response.data;

  //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
  //     // ? This is just for demo purpose
  //     const userData = getUserData();
  //     this.data.congratulations.name =
  //       userData.fullName.split(" ")[0] || userData.username;
  //   });
  // },
};
</script>


